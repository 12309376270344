
export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; ${expires}=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function getCookie(name = '') {
  const result = document.cookie.split(';')
    .find(el => el.trim().startsWith(`${name}=`))
    ?.split('=')[1]

  return result || null;
}
