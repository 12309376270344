import { createApp } from 'vue'
import App from './App.vue'
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App);

app.use(vue3GoogleLogin, {
  clientId: '913936190013-sc1ulvv5fi2k9ub1rgp7e90n8osh22vr.apps.googleusercontent.com'
});

app.config.errorHandler = (err, instance, info) => {

  // Handle the error globally
  console.err("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);

  // Add code for UI notifications, reporting or other error handling logic
};

app.mount('#appAuth')
