
async function apiFetch(path, body = null) {
  return fetch(`/api${path}`, {
    method: 'POST',
    ...(!body ? null : { body: JSON.stringify(body) }),
  }).then(r => r.json()).then(({ data, status, msg }) => {
    if (status === true) {
      return data;
    }
    throw new Error(msg || `ErrorStatus: ${status}`);
  })
}

export default {
  login: {
    google: (credential) => apiFetch('/glogin', { credential }),
  },
}