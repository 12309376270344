<template>
  <div :class="{disabled: loading }">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Login Page" /> -->
    <!-- <script src="https://accounts.google.com/gsi/client" async defer></script> -->
    <GoogleLogin
      v-if="!user?.id"
      :callback="callback"
      prompt
    >
    </GoogleLogin>
    <!-- :auto-login="false" -->
    <!-- <button>Login Using Google</button> -->
    <div>
      <span class="loader" v-if="loading"></span>
    </div>
    <div v-if="user && user?.id === 0" class="user">
      <img :src="user.picture" />
      <hr />
      <div>{{ user.name }}</div>
      <hr />
      <div>{{ user.email }}</div>
      <hr />
      <div>You don't have permission</div>
    </div>
    <pre v-if="user?.id" class="user">Refresh page</pre>
  </div>
</template>

<script>
// import { decodeCredential } from 'vue3-google-login'
import { setCookie } from './cookie' 
import api from './api'
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'AppAuth',
  components: {
    // HelloWorld
  },
  data() {
    return {
      loading: false,
      user: null,
      CLIENT_API: '913936190013-sc1ulvv5fi2k9ub1rgp7e90n8osh22vr.apps.googleusercontent.com',
    }
  },
  methods: {
    async callback(resp) {
      // const userData = decodeCredential(resp.credential)
      this.loading = true
      try {
        const user = await api.login.google(resp.credential)
        if (user && user.validKey) {
          localStorage.token = user.token
          localStorage.user = JSON.stringify({
            id: user.id,
            name: user.name,
            picture: user.picture,
            email: user.email,
          });
          setCookie(user.validKey, user.id, 7)
        }
        this.user = user || { id: 0 };
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    'user.id'(id) {
      const isDev = 'localhost' === window.location.hostname
      if (id) {
        this.loading = true
        if (isDev) {
          window.location.pathname = '/v2'
        } else {
          setTimeout(() => {
            this.loading = false
            window.location.reload()
          }, 250)
        }
      }
    },
  }
}
</script>

<style>
  html {
    background-color: #666c72;
  }
  #appAuth {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
<style scoped>
div.disabled::after {
  display: block;
  content: '';
  background-color: rgba(0, 0, 0, 0.374);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: rotate 1s linear infinite;
  z-index: 1;
  position: fixed;
  top: calc(50% - 50px);
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #000283;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #f6ff00;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}

.user {
  background-color: #b4b4b4;
  border-radius: 5px;
  padding: 10px 5px;
  max-width: 350px;
  margin: auto;
}
.user img {
  border-radius: 50%;
  border: 2px solid #1e1e89b8;
}
</style>
